var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "c_rl_course_package_check d-flex flex-column bbox_wrapper",
    },
    [
      _vm.showTop
        ? _c(
            "div",
            {
              staticClass:
                "top_bar_wrapper flex-shrink-0 d-flex align-center justify-end",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.coursePackage.checkstatus === 2,
                      expression: "coursePackage.checkstatus === 2",
                    },
                  ],
                  staticClass: "btn btn_dark cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doSubmit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      提交\n    ")]
              ),
              _c("div", {
                staticClass: "close cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doClose.apply(null, arguments)
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.initLoading
        ? _c(
            "div",
            {
              staticClass:
                "info_wrapper d-flex align-stretch justify-space-between flex-shrink-0",
            },
            [
              _c("div", { staticClass: "info_group d-flex align-start" }, [
                _c("div", { staticClass: "pic_group flex-shrink-0" }, [
                  !_vm.coursePackage.webpic
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text_group d-flex align-center justify-center",
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.coursePackage.title) +
                                "\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.coursePackage.webpic
                    ? _c("div", {
                        staticClass: "pic",
                        style: {
                          backgroundImage: `url(${_vm.$file_url}${_vm.coursePackage.webpic})`,
                        },
                      })
                    : _vm._e(),
                  _vm.coursePackage.webpic
                    ? _c("div", {
                        staticClass: "pic img_pic",
                        style: {
                          backgroundImage: `url(${_vm.$img_url}${_vm.coursePackage.webpic})`,
                        },
                      })
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "text_info_group d-flex flex-column align-start justify-space-between",
                  },
                  [
                    _c("div", { staticClass: "text_info_wrapper" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.coursePackage.title) +
                            "\n          "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "number_info d-flex align-center" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("\n              教师团队:\n            "),
                          ]),
                          _c("div", { staticClass: "text blod" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.coursePackage.teachernum) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "text mr-5" }, [
                            _vm._v("\n              人\n            "),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v("\n              任务数量:\n            "),
                          ]),
                          _c("div", { staticClass: "text blod" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.coursePackage.tasknum) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "text mr-5" }, [
                            _vm._v("\n              个\n            "),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v("\n              素材数量:\n            "),
                          ]),
                          _c("div", { staticClass: "text blod" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.coursePackage.filenum) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "text mr-5" }, [
                            _vm._v("\n              个\n            "),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "icon_info_group" }, [
                        _c("div", { staticClass: "icon com" }),
                        _c("div", { staticClass: "key" }, [
                          _vm._v("\n              单  位:\n            "),
                        ]),
                        _vm.coursePackage && _vm.coursePackage.createuser
                          ? _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.coursePackage.createuser
                                      .ownedEnterprises ===
                                      "优联信驰文化发展有限公司"
                                      ? "北京优联信驰文化发展有限公司"
                                      : _vm.coursePackage.createuser
                                          .ownedEnterprises
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "icon_info_group" }, [
                        _c("div", { staticClass: "icon type" }),
                        _c("div", { staticClass: "key" }, [
                          _vm._v("\n              课程类型:\n            "),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.courseType) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "icon_info_group" }, [
                        _c("div", { staticClass: "icon time" }),
                        _c("div", { staticClass: "key" }, [
                          _vm._v("\n              创建时间:\n            "),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.coursePackage.createTime)
                                  .format("yyyy-MM-DD")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      !_vm.auditMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "btn_wrapper d-flex align-center flex-shrink-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn_group cp d-flex align-center create",
                                  on: { click: _vm.createClassRoom },
                                },
                                [
                                  _c("div", { staticClass: "icon create" }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      "\n                建群上课\n              "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.showApplyBtn
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn_group cp d-flex align-center apply",
                                      on: { click: _vm.touchStudy },
                                    },
                                    [
                                      _c("div", { staticClass: "icon apply" }),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                报名学习\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showStartStudy
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn_group cp d-flex align-center start_study",
                                      on: { click: _vm.touchStartStudy },
                                    },
                                    [
                                      0
                                        ? _c("div", {
                                            staticClass: "icon apply",
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                开始学习\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showEdit
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn_group cp d-flex align-center apply",
                                      on: { click: _vm.touchEdit },
                                    },
                                    [
                                      _c("div", { staticClass: "icon edit" }),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                编辑\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
              _vm.auditMode
                ? _c(
                    "div",
                    {
                      staticClass:
                        "fun_group flex-shrink-0 align-center d-flex justify-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.coursePackage.checkstatus === 2,
                              expression: "coursePackage.checkstatus === 2",
                            },
                          ],
                          staticClass: "select_group",
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "bottom", trigger: "click" },
                              model: {
                                value: _vm.switchSelect,
                                callback: function ($$v) {
                                  _vm.switchSelect = $$v
                                },
                                expression: "switchSelect",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "c_rl_course_package_check_select_list_group",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select_item audit",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.doSelect(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                待审核\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select_item approve",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.doSelect(3)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                通过\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select_item fail",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.doSelect(4)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                未通过\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c("template", { slot: "reference" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "select_btn_group d-flex align-center justify-center cp",
                                    class: {
                                      [_vm.statusType]: true,
                                      on: _vm.switchSelect,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "icon" }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.statusText) +
                                          "\n              "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "symbol" }),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.coursePackage.checkstatus === 3 ||
                                _vm.coursePackage.checkstatus === 4,
                              expression:
                                "coursePackage.checkstatus === 3 || coursePackage.checkstatus === 4",
                            },
                          ],
                          staticClass: "check_result_group",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check_group d-flex align-center",
                              class: {
                                [_vm.statusType]: true,
                              },
                            },
                            [
                              _c("div", { staticClass: "icon" }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.statusText) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "check_info_group d-flex flex-column",
                            },
                            [
                              _vm.coursePackage.checkstatus === 4
                                ? _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n            未通过原因:\n          "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.coursePackage.checkstatus === 4
                                ? _c("div", { staticClass: "check_reason" }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.coursePackage.reason) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  class: {
                                    right: _vm.coursePackage.checkstatus === 4,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            审核人: " +
                                      _vm._s(_vm.coursePackage.checkUserName) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.viewMode
                ? _c(
                    "div",
                    {
                      staticClass:
                        "view_mode_wrapper d-flex flex-column align-end justify-space-between flex-shrink-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fun_group_wrapper d-flex align-center",
                        },
                        [
                          _c("div", {
                            staticClass: "btn like",
                            class: {
                              on: _vm.coursePackage.likeFlag,
                            },
                            on: { click: _vm.doLike },
                          }),
                          _c("div", {
                            staticClass: "btn collect",
                            class: {
                              on: _vm.coursePackage.customFlag,
                            },
                            on: { click: _vm.touchCollect },
                          }),
                          _c("div", {
                            staticClass: "btn share",
                            on: { click: _vm.doShare },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "number_wrapper d-flex align-start" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "number_group d-flex align-center flex-column",
                            },
                            [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.coursePackage.studyCount || 0) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "text flex-shrink-0" }, [
                                _vm._v("\n            学习\n          "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "number_group d-flex align-center flex-column",
                            },
                            [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.coursePackage.chatCount || 0) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "text flex-shrink-0" }, [
                                _vm._v("\n            建群量\n          "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "number_group d-flex align-center flex-column",
                            },
                            [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.coursePackage.likeCount || 0) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "text flex-shrink-0" }, [
                                _vm._v("\n            点赞量\n          "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "number_group d-flex align-center flex-column",
                            },
                            [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.coursePackage.collectCount || 0
                                    ) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "text flex-shrink-0" }, [
                                _vm._v("\n            收藏量\n          "),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.initLoading
        ? _c("div", { staticClass: "main_wrapper d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "type_group d-flex align-center flex-shrink-0" },
              [
                _vm._l(_vm.typeList, function (item) {
                  return [
                    _c(
                      "div",
                      {
                        key: item.type,
                        staticClass: "type",
                        class: {
                          active: _vm.type === item.type,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.changeType(item.type)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _c("div", { staticClass: "main_layout" }, [
              _c(
                "div",
                { staticClass: "content_wrapper d-flex align-stretch" },
                [
                  _c(
                    "div",
                    { staticClass: "content_group" },
                    [
                      _vm.type === 1
                        ? [
                            _c("SectionList", {
                              attrs: { "alert-task": _vm.alertTask },
                            }),
                          ]
                        : _vm._e(),
                      _vm.type === 2
                        ? [
                            _c(
                              "div",
                              { staticClass: "course_package_content_group" },
                              [
                                _c("div", {
                                  staticClass: "content",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.coursePackage.content
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "user_list_group d-flex flex-column flex-shrink-0",
                    },
                    [
                      _c("div", { staticClass: "title_group" }, [
                        _vm._v("\n            课程创建者\n          "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user_group d-flex align-center" },
                        [
                          _c("div", { staticClass: "avatar_group" }, [
                            _c("div", {
                              staticClass: "avatar",
                              style: {
                                backgroundImage: `url(${_vm.$avatar_url}${_vm.coursePackage.createuser.thumbnail}@!small200)`,
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "name_group" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.coursePackage.createuser.userName
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm.coursePackage && _vm.coursePackage.createuser
                              ? _c("div", { staticClass: "com" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.coursePackage.createuser
                                          .ownedEnterprises ===
                                          "优联信驰文化发展有限公司"
                                          ? "北京优联信驰文化发展有限公司"
                                          : _vm.coursePackage.createuser
                                              .ownedEnterprises
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm.coursePackage.taskteachers &&
                      _vm.coursePackage.taskteachers.length
                        ? [
                            _c("div", { staticClass: "title_group" }, [
                              _vm._v("\n              组课教师\n            "),
                            ]),
                            _vm._l(
                              _vm.coursePackage.taskteachers,
                              function (item, index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "user_group d-flex align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "avatar_group" },
                                        [
                                          _c("div", {
                                            staticClass: "avatar",
                                            style: {
                                              backgroundImage: `url(${_vm.$avatar_url}${item.thumbnail}@!small200)`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "name_group" }, [
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.userName) +
                                              "\n                  "
                                          ),
                                        ]),
                                        _c("div", { staticClass: "com" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                item.ownedEnterprises ===
                                                  "优联信驰文化发展有限公司"
                                                  ? "北京优联信驰文化发展有限公司"
                                                  : item.ownedEnterprises
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }